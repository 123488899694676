<template>
  <b-card>
    <div class="d-flex">
      <b-form-input
        id="filter-input"
        v-model="filter"
        type="search"
        class="col-md-4 col-xl-2"
        placeholder="Search Contact"
      ></b-form-input>
      <!--<b-button class="mb-3 ml-auto" variant="success" v-b-modal.editModal
        >+ New Contact</b-button
      >-->
    </div>
    <b-table
      hover
      bordered
      :filter="filter"
      responsive
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      class="contact-table"
    >
      <!-- A custom formatted column -->
      <template #cell(Actions)="data" width="100" class="text-center">
        <a class="mx-1 cursor-pointer" @click="viewItem(data.item)"
          ><feather type="eye" class="feather-sm"></feather
        ></a>
        <a
          class="mx-1 cursor-pointer text-danger"
          @click="editItem(data.item)"
          ><feather type="edit" class="text-danger feather-sm"></feather
        ></a>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="center"
    ></b-pagination>
    <!-- ====================================== -->
    <!-- Edit Contact box -->
    <!-- ====================================== -->
    <b-modal
      ref="edit-modal"
      id="editModal"
      hide-header
      @ok="changeWithdrawl()"
      @cancel="close"
      ok-title="Save"
    >
      <h4 class="mb-4">{{ formTitle }}</h4>
      <b-row>
        <b-col cols="12" lg="12" class="mb-3">
          <h6>Update Status</h6>
          <b-form-select v-model="editedItem.status" :options="paymentStatuses"></b-form-select>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "WithdrawlsTable",
  data: () => ({
    sortBy: "id",
    fields: [
      { key: "with_id", sortable: true },
      { key: "email", label: "User" },
      { key: "title", label: "Course" },
      { key: "amount", label: "Amount" },
      { key: "st", label: "Status" },
      { key: "request_date", label: "Request Date" },
      "Actions",
    ],
    items: null,
    filter: null,
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    url: null,
    editedIndex: -1,
    editedItem: {
      with_id: null,
      status: 0,
    },
    defaultItem: {
      with_id: null,
      status: 0,
    },
    paymentStatuses: [
      { text: "Pending", value: 0 },
      { text: "Approved", value: 1 }
    ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(["getWithdrawls","updateWithdrawl"]),
    initialize() {
      this.getWithdrawls().then(res => {
        this.items = res;
      })
    },
    editItem(item) {
      this.editedItem.with_id = item.with_id;
      this.editedItem.status = item.st;
      this.$refs["edit-modal"].show();
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.items.splice(index, 1);
    },
    close() {
      this.$refs["edit-modal"].hide();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      } else {
        this.items.push(this.editedItem);
      }
      this.close();
    },
    changeWithdrawl() {
      this.updateWithdrawl(this.editedItem).then(() => {
        this.close();
        this.initialize();
      })
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.editedItem.avatar = URL.createObjectURL(file);
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Request" : "Edit Request";
    },
    rows() {
      return this.items.length;
    },
  },
  watch: {},
};
</script>

<style>
.contact-table td {
  vertical-align: middle;
}
</style>